import http from "@/services/http";
type RequestData = {
  customAttributes?: any;
  clcInfo?: any;
};

type AddPayment = {
  basketId: string;
  paymentMethod: string;
  paymentSourceId: string;
  orderSource: string;
  c_SOInfo: string;
  c_ULAInfo: string;
  companyName: string;
  isZeroPriceOrder: boolean;
  sourceCode: string;
  paymentMode: string;
};

export async function add({
  basketId,
  paymentMethod,
  paymentSourceId,
  orderSource,
  c_SOInfo,
  c_ULAInfo,
  companyName,
  isZeroPriceOrder,
  sourceCode,
  paymentMode,
}: AddPayment) {
  const requestData: RequestData = {};
  if (paymentMethod == "DROPIN") {
    requestData.customAttributes = {
      c_drSourceId: paymentSourceId != "paypal" ? paymentSourceId : "",
      c_orderSource: orderSource,
      c_ULAInfo: c_ULAInfo,
      c_companyName: companyName,
      c_isZeroPriceOrder: isZeroPriceOrder,
      sourceCode: sourceCode,
    };
    if (paymentMode == "PAYPAL") {
      requestData.customAttributes["c_selectedPaymentMethod"] = "PAYPAL";
      requestData.customAttributes["c_paypalReturnURL"] =
        window.location.origin + "/confirmation?status=paypalSuccess";
    } else {
      requestData.customAttributes["c_selectedPaymentMethod"] = "CC";
      requestData.customAttributes["c_vendor3DSReturnURL"] =
        window.location.origin +
        "/confirmation?status=3dsSuccess&token=" +
        paymentSourceId;
      requestData.customAttributes["c_vendor3DSCancelURL"] =
        window.location.origin +
        "/confirmation?status=3dsFailure&token=" +
        paymentSourceId;
    }
  } else {
    requestData.clcInfo = {
      c_isCLC: true,
      c_SOInfo: c_SOInfo,
      c_orderSource: orderSource,
      c_ULAInfo: c_ULAInfo,
      c_companyName: companyName,
      sourceCode: sourceCode,
      c_selectedPaymentMethod: "CLC",
    };
  }
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/payment",
    {
      basket: {
        basketId: basketId,
      },
      ...requestData,
    }
  );
  return data;
}

export async function updateTaxIdentifier(basketId: string, taxData: any) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/basket/attributes",
    {
      basket: {
        basketId: basketId,
      },
      taxData: taxData,
    }
  );
  return data;
}
