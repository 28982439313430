import unauthenticatedHttp from "@/services/unauthenticatedHttp";

export async function fetchPath(id: any) {
  const idNumber: any = id.match(/(\d+)$/g); // Please remove this once backend is adjusted
  const identifier = idNumber.length ? idNumber[0] : id;
  const { data } = await unauthenticatedHttp().get(
    `${window.env.UNAUTH_API}/paths/${identifier}?includeChild=true`
  );

  return data;
}
