import { fetch } from "@/services/catalogService/courses";
import { fetch as fetchUnauth } from "@/services/unauthService/courses";
import isUnauth from "@/utils/isUnauth";

export async function getCourseRouteDetails(to: any) {
  const courseId = to.params.courseId;
  let c1 = false;
  let c2 = false;
  let route: any = {};
  let url = "";
  try {
    const data = isUnauth()
      ? await fetchUnauth(courseId, true)
      : await fetch(courseId, true);

    if (data.active === 0 || (isUnauth() && data?.tracks?.length > 0)) {
      c1 = true;
      route = {
        name: "explore-courses",
      };
    } else if (!isUnauth() && data?.tracks?.length > 0) {
      c2 = true;
      url = data.catalog_data ? data.catalog_data.url : data.url;
    }
  } catch (error) {
    if (!import.meta.env.TEST) {
      console.error("Error fetching course data:", error);
    }
  }

  return { c1, c2, route, url };
}
