import { z } from "zod";
import http from "@/services/http";

function handleError(message: string) {
  sessionStorage.setItem("errorMessage", message);
  window.location.href = window.location.origin + "/for-you";
}

function getParamsFromURL() {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);

  const contentType = url.pathname.split("/")[2];
  const contentId = url.pathname.split("/")[3];
  const customerId = params.get("customerId");
  const region = params.get("region");

  return { contentType, contentId, customerId, region };
}

function validateZodSchema(schema: any, data: any) {
  try {
    return schema.parse(data);
  } catch (e) {
    if (e instanceof z.ZodError) {
      e.errors.forEach((error: { path: any[]; message: any }) => {
        console.log("This is a Zod validation error");
        console.warn(`${error.path.join(".")} - ${error.message}`);
        console.log("Zod error end!");
      });
    } else {
      console.log("An unexpected zod error occurred:", e);
    }
  }
}

export async function checkAuthorization(
  contentType: string,
  contentId: any,
  customerId: any,
  region: string
) {
  const Content = z.object({
    id: z.number(),
    type: z.string(),
    guid: z.string(),
    title: z.string(),
    friendly_url: z.string().nullish().optional(),
  });

  const Lab = Content.omit({ friendly_url: true }).extend({
    url: z.string(),
  });

  const Path = Content.omit({ title: true }).extend({
    name: z.string(),
  });

  try {
    const response = await http().get(
      `${window.env.CX_INTEGRATION_API}/authorize/${contentType}/${contentId}/${customerId}/${region}`
    );

    if (contentType === "lab") {
      return validateZodSchema(Lab, response.data);
    } else if (contentType === "path") {
      return validateZodSchema(Path, response.data);
    } else {
      return validateZodSchema(Content, response.data);
    }
  } catch (e: any) {
    if (e.response) {
      if (e.response.status === 403) {
        handleError(
          "403 Forbidden: Authorization failed; the user doesn’t have permission to access the requested content"
        );
      } else if (e.response.status === 404) {
        handleError(
          "404 Not Found: The specified customer_id, course, or user does not exist, request is invalid"
        );
      } else {
        handleError(`Status: ${e.response.status} ${e.response.statusText}`);
      }
    } else {
      handleError("Network error occurred. Please try again later");
    }
  }
}

export async function handleDirectLaunch() {
  const { contentType, contentId, customerId, region } = getParamsFromURL();

  if (!contentType || !contentId || !customerId || !region) {
    handleError(
      "400 Bad Request Invalid parameter format or missing parameters"
    );
    return;
  }

  try {
    console.log("Making API call to authorize");
    const response = await checkAuthorization(
      contentType,
      contentId,
      customerId,
      region
    );
    if (response) {
      let customId;
      if (window.env.FRIENDLY_URL_FF && response.friendly_url) {
        customId = response.friendly_url;
      } else {
        customId = response.id;
      }

      switch (response.type) {
        case "lab":
          // read the lab url from the response and redirect to it
          window.location.href = response.url;
          break;
        case "practice-exam":
          // redirect to the practice exam page
          window.location.href = window.location.origin + "/exams/" + customId;
          break;
        default:
          // redirect to the content page
          window.location.href =
            window.location.origin + "/" + response.type + "/" + customId;
          break;
      }
    }
  } catch (error: any) {
    handleError(error.message || "An error occurred during the process.");
  }
}
