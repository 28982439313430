<script lang="ts">
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import RawHtmlBlock from "@/components/RawHtmlBlock.vue";
import dateFormats from "@/utils/dateFormats";

export default defineComponent({
  components: {
    RawHtmlBlock,
  },
  computed: {
    ...mapGetters({
      freeId: "content/freeId",
      announcementBanner: "commerce/announcementBanner",
    }),
    isPastUtc(): boolean {
      if (this.announcementBanner?.endDate) {
        return dateFormats.isPastUtc(this.announcementBanner.end_date);
      }
      return true;
    },
  },
});
</script>

<template>
  <div>
    <a
      v-if="freeId > 0"
      :href="'search?query=chatgpt AI AI4NE&subscription=' + freeId"
      class="block bg-blue-dark px-6 py-4 text-center text-16 leading-[1.2] -tracking-[0.0275rem] text-white-lightest hover:underline"
      active-class="underline"
      ><strong class="font-thicker"
        >Free AI training and tutorials from Cisco U.</strong
      >
      From entry to expert, gain real-world skills to succeed in AI.</a
    >
    <span
      v-else
      class="block bg-blue-dark px-6 py-4 text-center text-16 leading-[1.2] -tracking-[0.0275rem] text-white-lightest"
      ><strong class="font-thicker"
        >Free AI training and tutorials from Cisco U.</strong
      >
      From entry to expert, gain real-world skills to succeed in AI.</span
    >
    <div
      class="p-4 text-center font-medium text-16 bg-[#fdcd80]"
      v-if="
        announcementBanner?.message &&
        announcementBanner?.message != '' &&
        !isPastUtc
      "
    >
      <RawHtmlBlock
        class="inline pt-1"
        :content="announcementBanner?.message ?? ''"
      />
    </div>
  </div>
</template>
