import * as catalogService from "@/services/catalogService/tracks";
import { isEmpty } from "lodash-es";

type VideoIdOrder = number | string | string[];

export async function getVideoRouteDetails(to: any) {
  let c1 = false;
  let c2 = false;
  let route: any = {};
  if (!window.env.ENABLE_VIDEO_ROUTING) {
    return { c1, c2, route };
  }
  const trackID = to.params.videoSerieId;
  let videoIdOrder: VideoIdOrder = to.params.videoId;

  if (!trackID) {
    c1 = true;
  } else {
    try {
      if (!isValidVideoIdOrder(videoIdOrder)) {
        const data = await catalogService.fetch(trackID as string);

        if (data.active === 0 || data.Courses.length === 0) {
          c1 = true;
        } else {
          videoIdOrder = generateVideoIdOrder(data, videoIdOrder);
          if (videoIdOrder == "") {
            c1 = true;
          } else {
            c2 = true;
          }
        }
      }
    } catch (error) {
      if (!import.meta.env.TEST) {
        console.error("Error fetching track data:", error);
      }

      c1 = true;
    }
  }

  if (c1) {
    route = {
      name: "explore-videos",
    };
  } else if (c2) {
    route = {
      name: to.name,
      params: {
        videoSerieId: trackID,
        videoId: videoIdOrder,
      },
      query: to.query,
    };
  }

  return { c1, c2, route };
}

export function isValidVideoIdOrder(videoIdOrder: VideoIdOrder): boolean {
  return (
    !isEmpty(videoIdOrder) &&
    startsWithVideoOrderPrefix(videoIdOrder.toString())
  );
}

export function startsWithVideoOrderPrefix(str: string): boolean {
  const regex = new RegExp(`^${"video-"}\\d{2}-`);
  return regex.test(str);
}

export function generateVideoIdOrder(
  data: any,
  videoIdOrder: VideoIdOrder
): string {
  let videoId = videoIdOrder;
  if (isEmpty(videoIdOrder)) {
    videoId = data.Courses[0].friendly_url || data.Courses[0].id;
  }
  const order = data.Courses.findIndex(
    (course: any) =>
      course.friendly_url == `${videoId}` || `${course.id}` == `${videoId}`
  );
  if (order == -1) return "";
  const videoOrder = order < 9 ? `0${order + 1}` : `${order + 1}`;
  return `video-${videoOrder}-${videoId}`;
}
