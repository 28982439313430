import unauthenticatedHttp from "@/services/unauthenticatedHttp";

export async function fetchTutorial(id: string) {
  const { data } = await unauthenticatedHttp()
    .get(`${window.env.UNAUTH_API}/courses/${id}`)
    .catch((e) => {
      return e.response.data;
    });

  return data;
}
