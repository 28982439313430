import * as certifications from "@/services/unauthService/certifications";
import * as challenges from "@/services/unauthService/challengeContent";
import {
  getAnnouncementBanner,
  getSystemDowntime,
} from "@/services/unauthService/common";
import * as communitiesContent from "@/services/unauthService/communitiesContent";
import * as courses from "@/services/unauthService/courses";
import * as landingContent from "@/services/unauthService/landingContent";
import * as learningPathsContent from "@/services/unauthService/learningPathsContent";
import * as subscriptions from "@/services/unauthService/subscriptions";
import * as telemetry from "@/services/unauthService/telemetry";
import * as tutorialContent from "@/services/unauthService/tutorialContent";

export default {
  certifications,
  courses,
  communitiesContent,
  landingContent,
  learningPathsContent,
  subscriptions,
  telemetry,
  tutorialContent,
  challenges,
  getAnnouncementBanner,
  getSystemDowntime,
};
