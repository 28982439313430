import http from "@/services/http";

export async function getSystemDowntime() {
  const { data } = await http().get(
    window.env.UNAUTH_API + "/system-messages/COMMERCE_SYSTEM_DOWN_MESSAGE"
  );
  return data;
}

export async function getAnnouncementBanner() {
  const { data } = await http().get(
    window.env.UNAUTH_API + "/system-messages/CISCOU_ANNOUNCEMENT_BANNER"
  );
  return data;
}
