import { z } from "zod";

import unauthenticatedHttp from "@/services/unauthenticatedHttp";
import ExamWithCertification from "@/services/catalogService/ExamWithCertification";

export async function fetch(id: string, includeParent?: boolean) {
  const { data } = await unauthenticatedHttp().get(
    `${window.env.UNAUTH_API}/courses/${id}${includeParent ? "?includeParent=true" : ""}`
  );
  return data;
}

export async function getExam(
  examIds: Array<string>,
  includeCertifications?: boolean,
  includeTopics?: boolean
) {
  const { data } = await unauthenticatedHttp().post(
    `${window.env.UNAUTH_API}/certifications/exam-number`,
    {
      exam_number: examIds,
      include_certifications: includeCertifications,
      include_topics: includeTopics,
      include_prescribed_prep_material: true,
    }
  );
  return ExamWithCertification.parse(data.Data);
}

export async function contents() {
  await unauthenticatedHttp().get(`${window.env.UNAUTH_API}/contents`);
}

export async function retrieveUnauthCatalogItems(body: object) {
  const { data } = await unauthenticatedHttp().post(
    `${window.env.UNAUTH_API}/retrieveCatalogItems`,
    retrieveCatalogItemsBody.parse(body)
  );
  return data.Data;
}

const retrieveCatalogItemsBody = z.object({
  guids: z.array(z.string()),
  recommended_podcast: z.boolean().optional(),
  type: z.string().optional(),
});
