<script lang="ts">
import "@cisco-u/new-card/cu-new-card.js";
import "@cisco-u/icon-garden/cu-icon-garden.js";

import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import AnchorTargetBlank from "@/components/AnchorTargetBlank.vue";
import newCard from "@/components/contentBits/newCard";
import tagList from "@/components/contentBits/tagList.vue";
import RawHtmlBlock from "@/components/RawHtmlBlock.vue";
import { ALLACCESS, ESSENTIALS, FREE } from "@/consts/bundles";
import { progressCalc } from "@/utils/contentProgress";

export default defineComponent({
  emits: ["navigate", "bookmark", "ctaClick", "removeProgress"],
  components: {
    RawHtmlBlock,
    tagList,
    AnchorTargetBlank,
  },
  props: {
    isUnauth: {
      type: Boolean,
      default: false,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    result: {
      type: Object,
      required: true,
    },
    status: {
      type: Object,
      default: null,
    },
    cy: {
      type: String,
      required: true,
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    link: {
      type: [String, Number],
      default: "",
    },
    isAdminContent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isInViewPort: false,
    };
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      achievements: "user/achievements",
      userEntitlements: "user/entitlements",
      prices: "pricing/prices",
      singleTitleBundles: "pricing/singleTitleBundles",
      freeId: "content/freeId",
      essentialsId: "content/essentialsId",
      allAccessId: "content/allAccessId",
      revupCampaigns: "content/revupCampaigns",
    }),
    eventDate() {
      if (this.result?.type == "webinar") {
        return this.cardData.date;
      }
      return null;
    },
    authorName(): string {
      if (this.result?.author) {
        return this.result.author;
      }
      return "";
    },
    freeContent(): boolean {
      const contentBundle = this.result?.content_bundle;
      if (contentBundle && contentBundle.includes(this.freeId)) {
        return true;
      }
      return this.hasContentInGlobalProducts(this.freeId);
    },
    essentialsContent(): boolean {
      const contentBundle = this.result?.content_bundle;
      if (contentBundle && contentBundle.includes(this.essentialsId)) {
        return true;
      }
      return this.hasContentInGlobalProducts(this.essentialsId);
    },
    allAccessContent(): boolean {
      const contentBundle = this.result?.content_bundle;
      if (contentBundle && contentBundle.includes(this.allAccessId)) {
        return true;
      }
      return this.hasContentInGlobalProducts(this.allAccessId);
    },
    hasAllAccess(): boolean {
      return this.userEntitlements?.some(
        (entitlement: any) => entitlement?.content_bundle_name === ALLACCESS
      );
    },
    hasEssentials(): boolean {
      return this.userEntitlements?.some(
        (entitlement: any) =>
          entitlement?.content_bundle_name === ESSENTIALS && !this.hasAllAccess
      );
    },
    hasFree(): boolean {
      return this.userEntitlements?.some(
        (entitlement: any) =>
          entitlement?.content_bundle_name === FREE &&
          !this.hasEssentials &&
          !this.hasAllAccess
      );
    },
    isRevup(): boolean {
      if (!window.env.REV_UP_RECERT || this.result?.type !== "path") {
        return false;
      }

      const contentBundle = this.result?.content_bundle;
      if (!contentBundle || contentBundle?.length === 0) {
        return this.checkRevupInGlobalProducts();
      }

      return this.checkRevupInContentBundle(contentBundle);
    },
    freeUntil(): string {
      if (!window.env.REV_UP_RECERT || this.result?.type !== "path") {
        return "";
      }

      let campaignEndMessage = "Free until ";
      const contentBundle = this.result?.content_bundle;

      let endDate = null;
      if (!contentBundle || contentBundle.length === 0) {
        endDate = this.getGlobalProductEndDate();
      } else {
        endDate = this.getCampaignEndDate();
      }

      if (endDate) {
        const options: Intl.DateTimeFormatOptions = {
          year: "numeric",
          month: "long",
          day: "numeric",
        };
        campaignEndMessage += endDate.toLocaleDateString("en-US", options);
      }

      if (this.isRevup && this.hasAccessToCampaign()) {
        return campaignEndMessage;
      }

      return "";
    },
    displayName() {
      if (this.result?.name) {
        return `${this.result.name}`;
      }
      return this.result?.title ? this.result.title : "";
    },
    progressPercentage(): number | string {
      if (!this.result || this.isUnauth) return 0;

      const resultType = ["path", "tutorial", "course", "challenge"].includes(
        this.result.type
      )
        ? this.result.type
        : "course";

      if (resultType === "tutorial") {
        return (
          this.progressCalc(this.result, this.achievements, resultType) ||
          this.progressCalc(this.result, this.achievements, "course")
        );
      }
      if (resultType === "challenge") {
        const progress = this.progressCalc(
          this.result,
          this.achievements,
          resultType
        );
        return progress == 100 ? "Completed" : "";
      }

      return this.progressCalc(this.result, this.achievements, resultType);
    },
    cardData() {
      return newCard.dataObject(this.result, this.status);
    },
    canItBeRemoved() {
      const isPremium = this.freeContent
        ? !this.freeContent
        : this.cardData.premium;
      return (this.isRemovable || this.isAdminContent) && !isPremium;
    },
    customerAdminLink() {
      return window.env.CUSTOMER_ADMIN_URL + "/learners/profile";
    },
    isLocked(): boolean {
      if (this.isRevup) {
        return !this.isRevup;
      }

      if (window.env.ENABLE_LOCK_CONTENT) {
        return this.result?.lock_content;
      }

      if (this.freeContent) {
        return !this.freeContent;
      }

      return this.cardData.premium;
    },
  },
  mounted() {
    if (import.meta.env.TEST) {
      this.isInViewPort = true;
      this.applyStyles();
      return;
    }

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isInViewPort = true;
          this.applyStyles();
          observer.disconnect();
        }
      });
    });
    observer.observe(this.$el);
  },
  methods: {
    progressCalc,
    applyStyles() {
      const cuNewCard = this.$el.querySelector("cu-new-card");

      if (
        (cuNewCard && !cuNewCard.shadowRoot) ||
        !this.result?.is_partial ||
        import.meta.env.TEST
      )
        return;

      let sheet = new CSSStyleSheet();
      sheet.insertRule(".footer{flex-wrap:wrap}");
      sheet.insertRule(
        ".footer > .updated-text{flex-basis:100%;display:flex;justify-content:flex-end}"
      );

      cuNewCard.shadowRoot.adoptedStyleSheets = [
        ...cuNewCard.shadowRoot.adoptedStyleSheets,
        sheet,
      ];
    },
    handleClick(result: any) {
      result?.detail?.event?.preventDefault();
      this.$emit("navigate", this.result);
    },
    checkRevupInGlobalProducts(): boolean {
      return (
        this.result?.global_products?.some((product: any) =>
          this.revupCampaigns.some(
            (bundle: any) => product?.content_bundle_id === bundle.id
          )
        ) || false
      );
    },
    checkRevupInContentBundle(contentBundle: any): boolean {
      return this.revupCampaigns.some((bundle: any) =>
        contentBundle.some((id: any) => id === bundle.id)
      );
    },
    getCampaignEndDate(): Date | null {
      for (const bundle of this.revupCampaigns) {
        if (this.result?.content_bundle?.includes(bundle?.id)) {
          return new Date(bundle?.global_product?.eol_date);
        }
      }
      return null;
    },
    getGlobalProductEndDate(): Date | null {
      for (const product of this.result?.global_products || []) {
        for (const bundle of this.revupCampaigns) {
          if (product?.content_bundle_id === bundle.id) {
            return new Date(bundle?.global_product?.eol_date);
          }
        }
      }
      return null;
    },
    hasAccessToCampaign(): boolean {
      return (
        this.isUnauth ||
        (this.hasFree && (this.essentialsContent || this.allAccessContent)) ||
        (this.hasEssentials && this.allAccessContent)
      );
    },
    hasContentInGlobalProducts(contentId: string): boolean {
      return (
        this.result?.global_products?.some(
          (product: any) => product?.content_bundle_id === contentId
        ) || false
      );
    },
  },
});
</script>

<template>
  <div data-cy="card-cu">
    <cu-new-card
      v-if="isInViewPort && !isUnauth"
      class="h-[22rem]"
      :type="cardData.type"
      :bookmarked="inMyList(result)"
      :progress="progressPercentage || freeUntil"
      :removable="canItBeRemoved"
      :admin="isAdminContent"
      :premium="isLocked"
      :vendor="cardData.vendor"
      :author="authorName"
      :date="eventDate"
      :list="cardView == 'Grid' ? false : true"
      :data-cy="cy"
      :link="link"
      @cu-card.prevent="handleClick"
      @cu-card-bookmark.prevent="$emit('bookmark', result)"
      @cu-card-cta.prevent="$emit('ctaClick', result)"
      @cu-card-remove.prevent="$emit('removeProgress', result)"
    >
      <div slot="tags">
        <tag-list :content="result" :isRevup="isRevup" />
      </div>

      <div slot="heading">
        <RawHtmlBlock :content="displayName" class="line-clamp-3" />
        <span v-if="cardData?.type == 'learning-path' && result?.acronym != ''">
          &nbsp;| {{ " " + result?.acronym }}
        </span>
      </div>
      <div slot="description">
        {{ result.summary }}
      </div>
      <div slot="garden">
        <cu-icon-garden
          v-for="garden in cardData.cardGarden"
          :key="garden.type"
          :type="garden.type"
          :text="garden.text"
          horizontal="true"
        >
        </cu-icon-garden>
      </div>
      <div
        v-if="isAdminContent"
        slot="admin-tooltip"
        class="leading-normal tracking-wider"
      >
        As an admin, you can manage and remove your assigned learning through
        the
        <AnchorTargetBlank class="underline" :url="customerAdminLink"
          >Customer Admin Portal</AnchorTargetBlank
        >
      </div>
    </cu-new-card>
    <cu-new-card
      v-else-if="isInViewPort && isUnauth"
      class="h-[22rem]"
      :type="cardData.type"
      nobookmark=""
      :progress="progressPercentage || freeUntil"
      :premium="isLocked"
      :vendor="cardData.vendor"
      :author="authorName"
      :date="eventDate"
      :list="cardView == 'Grid' ? false : true"
      :data-cy="cy"
      :link="link"
      @click.prevent="false"
      @cu-card.prevent="$emit('navigate', result)"
      @cu-card-bookmark.prevent="$emit('bookmark', result)"
      @cu-card-cta.prevent="$emit('ctaClick', result)"
    >
      <div slot="tags">
        <tag-list :content="result" :isRevup="isRevup" />
      </div>
      <div slot="heading">
        <RawHtmlBlock :content="displayName" class="inline" />
        <span v-if="cardData?.type == 'learning-path' && result?.acronym != ''">
          &nbsp;| {{ " " + result?.acronym }}
        </span>
      </div>
      <div slot="description">
        {{ result.summary }}
      </div>
      <div slot="garden">
        <cu-icon-garden
          v-for="garden in cardData.cardGarden"
          :key="garden.type"
          :type="garden.type"
          :text="garden.text"
          horizontal="true"
        >
        </cu-icon-garden>
      </div>
    </cu-new-card>
  </div>
</template>

<style scoped>
[type="time"] {
  margin-right: 0.5rem;
  border: none;
  padding: 0;
  line-height: normal;
  background: none;
}
</style>
