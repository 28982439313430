import http from "@/services/http";

export async function create(id: string) {
  const { data } = await http().post(
    window.env.COMMERCE_API + "/commerce/order",
    {
      basket_id: id,
    }
  );
  return data;
}

export async function fail(orderNumber: string) {
  const { data } = await http().get(
    window.env.COMMERCE_API + "/commerce/fail-order/" + orderNumber
  );
  return data;
}
