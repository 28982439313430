import commerceService from "@/services/commerceService";
import unauthService from "@/services/unauthService";

export default {
  namespaced: true,
  state: {
    systemDowntime: null as any,
    announcementBanner: null as any,
  },
  getters: {
    systemDowntime: (state: any) => state.systemDowntime,
    announcementBanner: (state: any) => state.announcementBanner,
  },
  mutations: {
    setSystemDowntime(state: any, value: any) {
      state.systemDowntime = value;
    },
    setAnnouncementBanner(state: any, value: any) {
      state.announcementBanner = value;
    },
  },
  actions: {
    async fetchSystemDowntime({ commit }: any) {
      await commerceService
        .getSystemDowntime()
        .then((response: any) => {
          commit("setSystemDowntime", response);
        })
        .catch(() => {
          // do nothing
        });
    },
    async fetchAnnouncementBanner({ commit }: any) {
      await commerceService
        .getAnnouncementBanner()
        .then((response: any) => {
          commit("setAnnouncementBanner", response);
        })
        .catch(() => {
          // do nothing
        });
    },
    async fetchUnauthSystemDowntime({ commit }: any) {
      await unauthService
        .getSystemDowntime()
        .then((response: any) => {
          commit("setSystemDowntime", response);
        })
        .catch(() => {
          // do nothing
        });
    },
    async fetchUnauthAnnouncementBanner({ commit }: any) {
      await unauthService
        .getAnnouncementBanner()
        .then((response: any) => {
          commit("setAnnouncementBanner", response);
        })
        .catch(() => {
          // do nothing
        });
    },
  },
};
