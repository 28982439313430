import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat.js";
import duration from "dayjs/plugin/duration.js";
import relativeTime from "dayjs/plugin/relativeTime.js";
import utc from "dayjs/plugin/utc";

import { Duration } from "@/store/modules/duration";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

const fullMonthDateFormat = "MMMM D, YYYY";
const longDayMonthYearFormat = "MM/DD/YYYY";

export default {
  // month and year in short format, e.g. Jan 2021
  shortFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM") : "",

  // month and year in long format, e.g. January 2021
  monthAndYear: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MM/YYYY") : "",

  // day, month, and year in long format, e.g. 24 December 2020
  dayMonthYear: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("DD MMM YYYY") : "",

  // day in short format, e.g. 24
  dayFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("DD") : "",

  // day, month, and year in long format, e.g. December 24, 2020
  longDayFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM D, YYYY") : "",

  // month and day in long format, e.g. December 24, 2020
  longMonthFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format(fullMonthDateFormat) : "",

  // month and day in long format, e.g. December 24, 2020
  longMonthFmtUtc: (result: string): string =>
    dayjs(result).isValid()
      ? dayjs(result).utc().format(fullMonthDateFormat)
      : "",

  // year, month and day in long format, e.g. 01/01/2024
  longDayMonthYearFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format(longDayMonthYearFormat) : "",

  // month and day in long format, e.g. December 24th, 2020
  longMonthOrdFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMMM Do, YYYY") : "",

  // month and year in format, e.g. Sept, 2023
  monthYearComma: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM, YYYY") : "",

  // month, date and year in format, e.g. Aug. 4, 2024
  monthDotDateCommaYear: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("MMM. D, YYYY") : "",

  // month and day in long format, e.g. Saturday, Aug 17, 2024
  monthWithDayFmt: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).format("dddd, MMM D, YYYY") : "",

  // day, month, and year in long format, e.g. December 24, 2020
  longMonthDayNoYearFmtUtc: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).utc().format("MMMM DD") : "",

  //fetch current date in zulu format
  currentDate: (): string => dayjs().format("YYYY-MM-DDTHH:mm:ss[Z]"),

  // US date format, e.g.12/24/2020
  usDateFormat: (result: string): string =>
    dayjs(result).isValid() ? dayjs(result).utc().format("MM/DD/YYYY") : "",

  //fetch date difference in days, hours, minutes, e.g. 2 days 04 hours 30 minutes
  durationCounter: (futureDate: string, currentDate: string): Duration => {
    const days = dayjs(futureDate).diff(dayjs(currentDate), "days");
    let hours = dayjs(futureDate).diff(dayjs(currentDate), "hours");
    let minutes = dayjs(futureDate).diff(dayjs(currentDate), "minutes");
    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    return new Duration(days, hours, minutes);
  },

  isPastUtc: (date: string): boolean => dayjs(date).isBefore(dayjs().utc()),

  isNowWithinRange: (startDate: string, endDate: string): boolean =>
    dayjs().utc().isAfter(dayjs(startDate).utc()) &&
    dayjs().utc().isBefore(dayjs(endDate).utc()),
};
