<script lang="ts">
import "@cisco-u/tags/cu-tags.js";

import { defineComponent } from "vue";
import { mapGetters } from "vuex";

const retiringSoonTxt = "Retiring soon";

export default defineComponent({
  props: {
    content: {
      type: Object,
      required: true,
    },
    tags: {
      type: Array<string>,
      default: [],
    },
    isRevup: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      const isInContentBundle = this.content?.content_bundle?.includes(
        this.freeId
      );

      const isInGlobalProducts = this.content?.global_products?.some(
        (product: any) => {
          return product.content_bundle_id === this.freeId;
        }
      );

      return isInContentBundle || isInGlobalProducts;
    },
    newContent(): boolean {
      if (this.content?.type === "challenge") {
        const globalProduct = this.content?.global_products?.find(
          (product: any) => product?.is_single === 1
        );
        if (!globalProduct?.ga_date && !this.content?.ga_date) return false;
        const ga_date = new Date(
          globalProduct?.ga_date ?? this.content?.ga_date
        );
        ga_date.setDate(ga_date.getDate() + 30);
        return new Date() < ga_date;
      }
      if (!this.content?.created_at) return false;
      const created_at = new Date(this.content?.created_at);
      created_at.setDate(created_at.getDate() + 30);
      return new Date() < created_at;
    },
    isRetiringSoon(): boolean {
      if (!this.content) return false;

      const MS_PER_DAY = 24 * 60 * 60 * 1000;
      const currentDateObj = new Date();

      // Determine the correct eos_date to use
      const globalProduct = this.content?.global_products?.find(
        (product: any) => product?.is_single === 1
      );
      const eosDateStr = globalProduct?.eos_date ?? this.content?.eos_date;

      if (!eosDateStr) return false;

      // Check if SHOW_RETIRING_DATE is false and content type is not "challenge" or "learning-path"
      if (
        window.env.SHOW_RETIRING_DATE === false &&
        this.content?.type !== "challenge"
      ) {
        return false;
      }

      const eosDateObj = new Date(eosDateStr);

      // Check if the eosDateObj is a valid date
      if (isNaN(eosDateObj.getTime())) {
        console.error("Invalid EOS date provided.");
        return false;
      }

      // Calculate the difference in days
      const differenceInDays = Math.ceil(
        (eosDateObj.getTime() - currentDateObj.getTime()) / MS_PER_DAY
      );

      // Check if the current date is within 30 days of the EOS date
      return differenceInDays >= 0 && differenceInDays <= 30;
    },
    contentRetired(): string {
      if (!this.content) return "";
      if (window.env.LP_EOL === true && this.content?.type === "path") {
        switch (this.content?.content_retired) {
          case "RETIRING_SOON":
            return "Retiring soon";
          case "RETIRED":
            return "Retired";
          default:
            return "";
        }
      }
      return "";
    },
    results(): Array<string> {
      let data = this.content?.tags ?? [];
      data = Array.isArray(data) ? data : [data];

      data = data?.concat(this.tags.filter((tag) => !data.includes(tag)));
      data = data ?? [];

      // check if content is free, and add appropriate tags
      if (this.freeContent) {
        data = data.filter((tag: any) => tag !== "Free");
        data.push("Free");
      }

      // Add "New" tag if content is new
      if (!data.includes("New") && this.newContent && Array.isArray(data)) {
        data.push("New");
      }

      // Add "Rev Up to Recert" tag if content is part of a Rev Up campaign
      if (this.isRevup) {
        data = data.filter((tag: any) => tag !== "Rev Up to Recert");
        data = ["Rev Up to Recert"];
      }

      // remove any empty fields
      data = data.filter((tag: any) => tag !== "");

      return data;
    },
    isPartial(): boolean {
      return this.content?.is_partial === 1 && this.content?.type === "path";
    },
    hasRetiringTag(): string {
      let data = this.content.tags ?? [];
      // ensure that data is an array
      data = Array.isArray(data) ? data : [data];
      if (!data.includes(retiringSoonTxt) && this.isRetiringSoon) {
        return retiringSoonTxt;
      }
      return "";
    },
  },
});
</script>

<template>
  <div class="flex flex-wrap items-center gap-2">
    <cu-tags
      v-for="tag in results"
      :text="tag"
      :key="tag"
      size="s"
      bgColor=""
      color="white"
      style="--bg-color: #0076d5"
    />
    <cu-tags
      v-if="isRetiringSoon"
      text="Retiring soon"
      size="s"
      :blue="true"
      bgColor="blue-light"
      ghost=""
    />
    <cu-tags
      v-if="contentRetired !== ''"
      :text="contentRetired"
      size="s"
      bgColor=""
      color="black"
      style="--bg-color: #fee6c0"
    />
    <cu-tags
      v-if="isPartial"
      text="Special release"
      size="s"
      bgColor="blue-light"
      ghost=""
    />
  </div>
</template>
