<script lang="ts">
import "@cisco-u/marquee-card/cu-marquee-card.js";

import { defineComponent } from "vue";

import RawHtmlBlock from "@/components/RawHtmlBlock.vue";
import marketingManagementService from "@/services/marketingManagementService";
import {
  Marquee,
  MarqueeResponseResult,
} from "@/services/marketingManagementService/marquees";
import { EventBus } from "@/store/eventBus";
import StripHtml from "@/utils/StripHtml";
import telemetry from "@/utils/telemetry";

type MarketingTextType = "marquee-text" | "button-text";

interface MarqueeButton {
  type: string;
  text: string;
  dark: boolean | null;
}

interface MarqueeDisplayObj {
  typeName: string;
  url: string;
  newTab: boolean | null;
  title: string;
  heading: string;
  description: string;
  primaryButton: MarqueeButton | null;
  secondaryButton: MarqueeButton | null;
  imageUrl: string | null;
  bgColor: string | null;
  textColor: string | null;
  alignment: string | null;
}

export default defineComponent({
  components: {
    RawHtmlBlock,
  },
  emits: ["openGoSurveyModal"],
  props: {
    status: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      surveyCompleted: false,
      codeButtonMap: new Map<string, MarqueeButton>(),
      marqueeOne: {
        typeName: "recommend-update",
        title: "Recommendations",
        heading: "Cisco U. evolves <br />as you do",
        description:
          "Revisit your interests to see <strong>certifications</strong> and <strong>training</strong> that meet your current goals.",
        primaryButton: {
          type: "primary",
          text: "Update interests",
          width: "10.5rem",
        },
      },
      marqueeOneAlt: {
        typeName: "recommend-update",
        title: "Recommendations",
        heading: "Tell us about your Interests",
        description:
          "To unlock Cisco U. recommendations. Tell us more about your goals!",
        primaryButton: {
          type: "secondary",
          text: "Take survey",
          width: "10rem",
        },
      },
      marqueeTwo: {} as any,
      marqueeThree: {} as any,
    };
  },
  computed: {
    isCommunity() {
      const communityRoutes = [
        "community",
        "my-list",
        "dashboard",
        "my-account",
      ];
      return (
        this.$route && communityRoutes.includes(this.$route.name as string)
      );
    },
    getUser() {
      return this.status?.surveyCompleted ?? false;
    },
    loading() {
      return !(
        Object.keys(this.marqueeTwo).length > 0 &&
        Object.keys(this.marqueeThree).length > 0
      );
    },
  },

  watch: {
    status(newVal, oldVal) {
      if (newVal) this.fetchMarquees();
    },
  },

  mounted() {
    EventBus.on("updatesurveyCompleted", (data: boolean) => {
      this.surveyCompleted = data;
    });
    if (this.status) this.fetchMarquees();
  },
  methods: {
    fetchMarquees() {
      marketingManagementService.Marquees.fetch()
        .then((data) => {
          const marqueeData: MarqueeResponseResult = data;

          const marqueesList: Marquee[] =
            this.retrieveMarqueesList(marqueeData);
          this.setMarqueeButtonsData(marqueeData);

          marqueesList.forEach((marquee: Marquee) => {
            this.fillMarqueeData(marquee);
          });
        })
        .catch((e: any) => {
          if (import.meta.env.DEV && !import.meta.env.TEST) {
            console.error(
              `Marketing Management Service Api Error in MarqueeCard - fetchMarquees(): ${e}`
            );
          }
        });
    },
    retrieveMarqueesList(marqueeData: MarqueeResponseResult): Marquee[] {
      const marqueeList: Marquee[] = [];
      const cardOptions = marqueeData.data.marketing_card_options;
      const textOptions = marqueeData.data.marketing_text_options;

      cardOptions.forEach((cOption: any) => {
        const marketingCode: string = cOption.marketing_code;
        let marqueeMarketingCodeFound: boolean = false;

        switch (marketingCode) {
          case "marquee2":
          case "marquee3":
            marqueeMarketingCodeFound = true;
        }

        if (marqueeMarketingCodeFound) {
          const tOptions = this.returnMarketingTextOptionsByType(
            marketingCode,
            textOptions,
            "marquee-text"
          );

          const marquee: Marquee = {
            marketing_card_options: cOption,
            marketing_text_options: tOptions,
          };

          marqueeList.push(marquee);
        }
      });

      return marqueeList;
    },
    setMarqueeButtonsData(marqueeData: MarqueeResponseResult): void {
      const cardOptions = marqueeData.data.marketing_card_options;
      const textOptions = marqueeData.data.marketing_text_options;

      cardOptions.forEach((cOption: any) => {
        const marketingCode: string = cOption.marketing_code;
        let btnMarketingCodeFound: boolean = false;

        switch (marketingCode) {
          case "marquee2_button1":
          case "marquee2_button2":
          case "marquee3_button1":
            btnMarketingCodeFound = true;
        }

        if (btnMarketingCodeFound) {
          const tOptions = this.returnMarketingTextOptionsByType(
            marketingCode,
            textOptions,
            "button-text"
          );
          const buttonText: string = tOptions.length
            ? tOptions[0].marketing_text
            : "";

          const marqueeButton: MarqueeButton = {
            type: cOption.card_color,
            text: buttonText,
            dark: null,
          };

          this.codeButtonMap.set(marketingCode, marqueeButton);
        }
      });
    },
    async fillMarqueeData(marquee: Marquee) {
      const marketingCode: string =
        marquee.marketing_card_options.marketing_code;

      if (marketingCode === "marquee2") {
        this.marqueeTwo = this.createMarqueeDisplayObj(marquee);
      } else if (marketingCode === "marquee3") {
        this.marqueeThree = this.createMarqueeDisplayObj(marquee);
      }
    },
    returnMarketingTextOptionsByType(
      marketingCode: string,
      marketingTextOptions: any[],
      textType: MarketingTextType
    ): any[] {
      return marketingTextOptions.filter((option: any) => {
        const mCode = option.marketing_code;

        if (textType === "marquee-text") {
          return mCode.includes(marketingCode) && !mCode.includes("button");
        } else {
          return mCode.includes(marketingCode) && mCode.includes("button");
        }
      });
    },
    returnTextFieldData(textOptions: any[], fieldName: string): string {
      return (
        textOptions.find(
          (option: any) =>
            option["marketing_code" as keyof typeof option] === fieldName
        )?.marketing_text ?? ""
      );
    },
    returnMarqueeButton(
      cardOptionMarketingCode: string,
      buttonCodePartial: string
    ): MarqueeButton | null {
      return (
        this.codeButtonMap.get(cardOptionMarketingCode + buttonCodePartial) ??
        null
      );
    },
    createMarqueeDisplayObj(marquee: Marquee): MarqueeDisplayObj {
      const cardOption = marquee.marketing_card_options;
      const textOptions = marquee.marketing_text_options;

      const marketingCode = cardOption["marketing_code"];
      const marqueeType = cardOption["card_color"];
      const marqueeUrl = cardOption["link"];
      const marqueeNewTab = cardOption["target"];
      const marqueeImg =
        marqueeType === "custom"
          ? this.imageUrl(cardOption["image_url"])
          : null;
      const marqueeBgColor =
        marqueeType === "custom" ? cardOption["bg_color"] : null;
      const marqueeTextColor =
        marqueeType === "custom" ? cardOption["text_color"] : null;
      const marqueeAlignment =
        marqueeType === "custom" ? cardOption["alignment"] : null;

      const marqueeTitle = this.returnTextFieldData(
        textOptions,
        `${marketingCode}_title`
      );
      const marqueeHeading = this.returnTextFieldData(
        textOptions,
        `${marketingCode}_heading`
      );
      const marqueeDescription = this.returnTextFieldData(
        textOptions,
        `${marketingCode}_description`
      );

      const marqueePrimaryButton = this.returnMarqueeButton(
        marketingCode,
        "_button1"
      );
      const marqueeSecondaryButton = this.returnMarqueeButton(
        marketingCode,
        "_button2"
      );

      const marqueeObj: MarqueeDisplayObj = {
        typeName: marqueeType,
        url: marqueeUrl,
        newTab: marqueeNewTab,
        title: marqueeTitle,
        heading: marqueeHeading,
        description: marqueeDescription,
        primaryButton: marqueePrimaryButton,
        secondaryButton: marqueeSecondaryButton,
        imageUrl: marqueeImg || null,
        bgColor: marqueeBgColor || null,
        textColor: marqueeTextColor || null,
        alignment: marqueeAlignment || null,
      };

      return marqueeObj;
    },
    updateSurveyCompleteStatus() {
      this.surveyCompleted = true;
    },
    marquee1Link() {
      if (this.getUser || this.surveyCompleted) {
        this.sendTelemetry(
          window.location.href,
          {
            type: "cardDetails",
            typeName: this.marqueeOne.typeName,
            heading: this.stripHTML(this.marqueeOne.heading),
            description: this.stripHTML(this.marqueeOne.description),
          },
          this.marqueeOne.heading
        );
      } else {
        this.sendTelemetry(
          window.location.href,
          {
            type: "cardDetails",
            typeName: this.marqueeOneAlt.typeName,
            heading: this.stripHTML(this.marqueeOneAlt.heading),
            description: this.stripHTML(this.marqueeOneAlt.description),
          },
          this.marqueeOneAlt.heading
        );
      }
      this.$emit("openGoSurveyModal");
    },
    marquee2Link() {
      this.sendTelemetry(
        this.marqueeTwo.url,
        {
          type: "cardDetails",
          typeName: this.marqueeTwo.typeName,
          heading: this.marqueeTwo.heading
            ? this.stripHTML(this.marqueeTwo.heading)
            : "",
          description: this.marqueeTwo.description
            ? this.stripHTML(this.marqueeTwo.description)
            : "",
          title: this.marqueeTwo.title,
        },
        this.marqueeTwo.heading
      );

      if (this.marqueeTwo.newTab)
        window.open(this.marqueeTwo.url, "_blank", "noopener");
      else window.location.href = this.marqueeTwo.url;
    },
    marquee3Link() {
      this.sendTelemetry(
        this.marqueeThree.url,
        {
          type: "cardDetails",
          typeName: this.marqueeThree.typeName,
          heading: this.marqueeThree.heading
            ? this.stripHTML(this.marqueeThree.heading)
            : "",
          description: this.marqueeThree.description
            ? this.stripHTML(this.marqueeThree.description)
            : "",
          title: this.marqueeThree.title,
        },
        this.marqueeThree.heading
      );

      if (this.marqueeThree.newTab)
        window.open(this.marqueeThree.url, "_blank", "noopener");
      else window.location.href = this.marqueeThree.url;
    },
    sendTelemetry(url: string, marqueeObj: any, objTitle: string) {
      telemetry.marqueeCardDetails(marqueeObj, this.stripHTML(objTitle), url);
    },
    stripHTML(text: string) {
      return StripHtml(text);
    },
    imageUrl(imgUrl: string): string {
      if (!imgUrl) {
        return "";
      }

      const cloudfrontUrl = window.env.CLOUDFRONT_URL + "marketing-images/";

      const fileName = imgUrl.split("/").pop() ?? "";

      const fileTypeIndex = fileName.lastIndexOf(".");
      const fileType = fileName.slice(fileTypeIndex);

      let encodeName = fileName.slice(0, fileTypeIndex).replace("/\+/g", " ");

      return cloudfrontUrl + encodeName + fileType;
    },
  },
});
</script>

<template>
  <div
    data-cy="marquee-top"
    ref="marquee-top"
    :class="[
      'mx-auto mt-10 flex w-full flex-col items-center justify-center gap-4 md:mt-0 md:flex-row md:flex-wrap lg:w-[80rem]',
      { 'mt-14': isCommunity, 'mt-1': !isCommunity },
    ]"
  >
    <div id="join-usy">
      <div v-if="getUser || surveyCompleted">
        <cu-marquee-card
          data-cy="marquee-card-1"
          :type="marqueeOne.typeName"
          :cardtitle="marqueeOne.title"
          position="first"
          @marquee-primary="marquee1Link"
          :primarybutton="JSON.stringify(marqueeOne.primaryButton)"
        >
          <RawHtmlBlock slot="heading" :content="marqueeOne.heading" />

          <RawHtmlBlock slot="description" :content="marqueeOne.description" />
        </cu-marquee-card>
      </div>
      <div v-else>
        <cu-marquee-card
          data-cy="marquee-card-1"
          :type="marqueeOneAlt.typeName"
          :cardtitle="marqueeOneAlt.title"
          position="first"
          @marquee-primary="marquee1Link"
          :primarybutton="JSON.stringify(marqueeOneAlt.primaryButton)"
        >
          <RawHtmlBlock slot="heading" :content="marqueeOneAlt.heading" />

          <RawHtmlBlock
            slot="description"
            :content="marqueeOneAlt.description"
          />
        </cu-marquee-card>
      </div>
    </div>
    <div v-if="loading">
      <div
        class="flex h-64 w-[21.5rem] flex-col items-center justify-center bg-gray-medium px-4 font-cisco text-white"
      >
        <p class="text-center text-xl">Loading</p>
        <div class="mt-8 flex gap-1">
          <span class="dot dot-1"></span><span class="dot dot-2"></span
          ><span class="dot dot-3"></span><span class="dot dot-4"></span>
        </div>
      </div>
    </div>
    <div class="relative" v-else>
      <cu-marquee-card
        data-cy="marquee-card-2"
        :type="marqueeTwo.typeName"
        :cardtitle="marqueeTwo.title"
        headingtype="h1"
        @marquee-primary="marquee2Link"
        :location="marqueeTwo.location ? marqueeTwo.location : null"
        :primarybutton="JSON.stringify(marqueeTwo.primaryButton)"
        :bgimage="marqueeTwo.imageUrl || null"
        :bgcolor="marqueeTwo.bgColor || null"
        :color="marqueeTwo.textColor || null"
        :alignment="marqueeTwo.alignment || null"
      >
        <RawHtmlBlock
          v-show="marqueeTwo.heading"
          slot="heading"
          :content="marqueeTwo.heading"
        />

        <RawHtmlBlock
          v-show="marqueeTwo.description"
          slot="description"
          :content="marqueeTwo.description"
        />
      </cu-marquee-card>
    </div>
    <div v-if="loading">
      <div
        class="flex h-64 w-[21.5rem] flex-col items-center justify-center bg-gray-medium px-4 font-cisco text-white"
      >
        <p class="text-center text-xl">Loading</p>
        <div class="mt-8 flex gap-1">
          <span class="dot dot-1"></span><span class="dot dot-2"></span
          ><span class="dot dot-3"></span><span class="dot dot-4"></span>
        </div>
      </div>
    </div>
    <cu-marquee-card
      v-else
      data-cy="marquee-card-3"
      :type="marqueeThree.typeName"
      :cardtitle="marqueeThree.title ? marqueeThree.title : null"
      position="last"
      @marquee-primary="marquee3Link"
      :location="marqueeThree.location ? marqueeThree.location : null"
      :primarybutton="JSON.stringify(marqueeThree.primaryButton)"
      :bgimage="marqueeThree.imageUrl || null"
      :bgcolor="marqueeThree.bgColor || null"
      :color="marqueeThree.textColor || null"
      :alignment="marqueeThree.alignment || null"
    >
      <RawHtmlBlock
        v-show="marqueeThree.heading"
        slot="heading"
        :content="marqueeThree.heading"
      />

      <RawHtmlBlock
        v-show="marqueeThree.description"
        slot="description"
        :content="marqueeThree.description"
      />
    </cu-marquee-card>
  </div>
</template>

<style scoped>
.dot {
  width: 0.75rem;
  height: 0.75rem;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  animation: bounce 1s infinite ease-in-out;
  margin: 0 4px;
}

.dot-1 {
  animation-delay: 0s;
}

.dot-2 {
  animation-delay: 0.2s;
}

.dot-3 {
  animation-delay: 0.4s;
}

.dot-4 {
  animation-delay: 0.6s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-0.5rem);
  }

  100% {
    transform: translateY(0);
  }
}
</style>
