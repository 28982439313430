<script lang="ts">
import { defineComponent } from "vue";

import aiIconDefault from "@/assets/images/ai-prompt-default.svg";
import aiIconProcessing from "@/assets/images/ai-prompt-processing.svg";
import aiIconResponding from "@/assets/images/ai-prompt-responding.svg";
import { EventBus } from "@/store/eventBus";

export default defineComponent({
  data() {
    return {
      defaultState: "default" as const,
      currentState: "default",
      stateIconMap: new Map<string, string>([
        ["default", aiIconDefault],
        ["processing", aiIconProcessing],
        ["responding", aiIconResponding],
      ]),
      aiIconDefault,
      aiIconProcessing,
      aiIconResponding,
    };
  },
  mounted() {
    EventBus.on("updatePromptBtnState", (promptState: string) => {
      if (this.stateIconMap.has(promptState)) {
        this.currentState = promptState;
      }
    });
  },
  computed: {
    currentStateDisplay() {
      return this.stateIconMap.get(this.currentState);
    },
  },
  methods: {
    showGenAi() {
      return window.env.SHOW_GEN_AI;
    },
    openAiPrompt() {
      if (this.currentState === this.defaultState) {
        if (this.showGenAi()) {
          window.location.href = window.env.GEN_AI_URL;
        }
      }
    },
  },
});
</script>

<template>
  <button
    data-cy="ai-prompt-btn"
    @click="openAiPrompt"
    @keyup.enter="openAiPrompt"
    @keyup.space="openAiPrompt"
    tabindex="0"
  >
    <img
      :src="currentStateDisplay"
      alt="ai Prompt Icon"
      class="h-[1.625rem] w-[1.625rem]"
    />
  </button>
</template>
