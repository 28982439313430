<script lang="ts">
import { debounce } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import theCard from "@/components/contentBits/theCard.vue";
import { ESSENTIALS, FREE } from "@/consts/bundles";
import { removeProgress } from "@/services/middlewareService/learnerProgress";
import { EventBus } from "@/store/eventBus";
import { urlContentTypes } from "@/utils/contentRemovable";
import isUnauth from "@/utils/isUnauth";
import telemetry from "@/utils/telemetry";

export default defineComponent({
  components: {
    theCard,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    relevance: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    topicView: {
      type: Boolean,
      default: false,
    },
    isAdminContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      achievements: "user/achievements",
      prices: "pricing/prices",
      singleTitleBundles: "pricing/singleTitleBundles",
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result?.guid);
    },
    resultLink() {
      let pathId;
      if (window.env.FRIENDLY_URL_FF && this.result?.friendly_url?.length > 0) {
        pathId = this.result.friendly_url;
      } else {
        pathId = this.result.id;
      }

      if (!pathId) {
        return "";
      }
      return this.$router.resolve({
        name: "Path",
        params: { pathId },
      }).href;
    },
  },
  methods: {
    isUnauth,
    ...mapMutations({
      removeFromMyProgress: "user/removeFromMyProgress",
    }),
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
      fetchProgress: "user/fetchProgress",
    }),
    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: content,
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    openCartModal() {
      const cartContent = {
        data: [
          {
            id: this.result.id,
            name: this.result.name,
            guid: this.result.guid,
            acronym: this.result.acronym,
            type: this.result.type,
            display_version: this.result.display_version,
          },
        ],
        courseCard: false,
      };
      EventBus.emit("openCartModal", cartContent);
    },

    async sendTelemetry() {
      const telemetryObject = {
        action: "launch-contentType",
        url: this.resultLink,
        title: this.result.title ? this.result.title : this.result.name,
        id: this.result.id,
        page: this.$route.fullPath,
        type: "learning-path",
        relevance: this.result.relevance,
        sortOrder: this.index,
        accessObject: {} as any,
        assignedContent: undefined as any,
      };
      if (this.showPremium) {
        if (
          this.status?.bundles?.entitlements?.some(
            (entitlement: any) => entitlement.content_bundle_name === ESSENTIALS
          )
        ) {
          telemetryObject.accessObject.contentAccessLevel = ESSENTIALS;
        } else {
          telemetryObject.accessObject.contentAccessLevel = FREE;
        }

        telemetryObject.accessObject.type = "contentEntitlementAccess";
        telemetryObject.accessObject.status = "locked";
      }

      if (this.result.assigned_content) {
        telemetryObject.assignedContent =
          this.result.assigned_content?.isAssigned;
        telemetryObject.sortOrder = this.result.assigned_content?.index;
      }

      let telemetryResponse = telemetry.external(
        telemetryObject,
        this.isUnauth()
      );
      telemetryResponse
        .then(() => {
          this.$router.push(this.resultLink);
        })
        .catch(() => {
          // do nothing
        });
    },

    async sendRmTelemetry(content: any) {
      const contentId = content.content_id || content.id || "";
      return await telemetry.remove_from_myLearning(
        window.location.origin + this.$route.fullPath,
        `/${urlContentTypes(content.type)}/${contentId}`,
        content.title || content.name || "",
        content.type,
        contentId,
        this.isUnauth()
      );
    },

    async removeFromMyProgressList(content: any) {
      removeProgress(content.guid, content.type)
        .then(() => {
          this.sendRmTelemetry(content);
          this.removeFromMyProgress(content);
          this.fetchProgress();
        })
        .catch(() => {
          // do nothing
        });
    },
  },
});
</script>

<template>
  <div
    v-show="result"
    data-cy="path-card"
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView === 'Grid' && !topicView,
        'w-full': cardView != 'Grid',
        'w-[22rem]': topicView,
      },
    ]"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :link="resultLink"
      :status="status"
      :cy="'path-card-link'"
      :is-removable="isRemovable"
      @navigate="sendTelemetry()"
      :is-admin-content="isAdminContent"
      @bookmark="debouncedBookmarkMe(result)"
      @cta-click="openCartModal()"
      @remove-progress="removeFromMyProgressList(result)"
    />
  </div>
</template>
