<script lang="ts">
import "@cisco-u/reicon-lock/cu-reicon-lock.js";

import { debounce, isString } from "lodash-es";
import { defineComponent } from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";

import theCard from "@/components/contentBits/theCard.vue";
import { ESSENTIALS, FREE } from "@/consts/bundles";
import { getCoursePaths } from "@/services/catalogService/courses";
import { removeProgress } from "@/services/middlewareService/learnerProgress";
import { EventBus } from "@/store/eventBus";
import { urlContentTypes } from "@/utils/contentRemovable";
import isUnauth from "@/utils/isUnauth";
import linkBackFormat from "@/utils/linkBackFormat";
import telemetry from "@/utils/telemetry";
import { isXylemeSyndicateCourse } from "@/utils/validateUrl";

export default defineComponent({
  name: "CertCard",
  components: {
    theCard,
  },
  props: {
    result: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    status: {
      type: Object,
      default: null,
    },
    cardView: {
      type: String,
      default: "Grid",
    },
    isRemovable: {
      type: Boolean,
      default: false,
    },
    topicView: {
      type: Boolean,
      default: false,
    },
    isAdminContent: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      click: false,
      paths: null as any,
      cartData: {},
      modalReady: false,
      openModal: false,
    };
  },

  computed: {
    ...mapGetters({
      inMyList: "user/inMyList",
      achievements: "user/achievements",
      prices: "pricing/prices",
      singleTitleBundles: "pricing/singleTitleBundles",
      freeId: "content/freeId",
    }),
    freeContent(): boolean {
      return this.result?.content_bundle?.includes(this.freeId);
    },
    subLink() {
      return this.$router.resolve({
        name: "subscription",
      }).href;
    },
    showPremium(): boolean {
      return !this.status?.userEntitlements?.includes(this.result?.guid);
    },
    hasParent(): boolean {
      return window.env.ENABLE_COURSE_OVERVIEW
        ? this.result?.has_parent === true
        : true;
    },
  },
  methods: {
    isUnauth,
    ...mapMutations({
      removeFromMyProgress: "user/removeFromMyProgress",
    }),
    ...mapActions({
      fetchListByType: "user/fetchListByType",
      removeContentFromMyList: "user/removeContentFromMyList",
      addContentToMyList: "user/addContentToMyList",
      rm_LearningProgress: "user/rm_LearningProgress",
      fetchProgress: "user/fetchProgress",
    }),

    bookmarkMe(content: any) {
      this.inMyList(content)
        ? this.removeContentFromMyList({
            routeName: this.$route.name,
            result: content,
          })
        : this.addContentToMyList({
            routeName: this.$route.name,
            result: content,
          });
    },
    debouncedBookmarkMe: debounce(function (content: any) {
      // @ts-ignore
      this.bookmarkMe(content);
    }, 500),
    whereTo() {
      this.getCoursePaths(this.result.id);
      if (this.showPremium && this.hasParent) {
        this.click = true;
      } else {
        this.click = false;
        this.sendTelemetry();
      }
    },
    hideClicked() {
      if (this.click && this.showPremium) {
        this.click = false;
      }
      if (this.click && this.hasParent) {
        this.click = false;
      }
    },
    resourceUrl() {
      let url = this.result.catalog_data
        ? this.result.catalog_data.url
        : this.result.url;

      if (!this.hasParent) {
        url = this.result.friendly_url
          ? "/course/" + this.result.friendly_url
          : "/course/" + this.result.id;
      }

      if (!url || (url && url.length < 5)) {
        const courseId = this.result.friendly_url ?? this.result.id;
        if (!courseId) {
          return "";
        }
        url = this.$router.resolve({
          name: "Course",
          params: { courseId },
        }).href;
      }

      let title: string = (this.$route?.meta?.title as string) ?? "";

      title += (this.$route?.params?.type as string) ?? "";

      return linkBackFormat(url, title);
    },
    openCartModal() {
      EventBus.emit("openCartModal", {
        data: this.paths,
        courseCard: true,
      });
    },

    async getCoursePaths(id: number) {
      if (!this.isUnauth()) {
        await getCoursePaths(id)
          .then((data) => {
            this.paths = data?.paths;
          })
          .catch(() => {
            this.$router.push({ name: "error" });
          });
      }
    },

    async sendTelemetry() {
      const telemetryObject = this.createTelemetryObject();
      const telemetryResponse = telemetry.external(
        telemetryObject,
        this.isUnauth()
      );

      telemetryResponse
        .then(() => {
          this.handleTelemetryResponse();
        })
        .catch(() => {
          // do nothing
        });
    },

    createTelemetryObject() {
      const telemetryObject = {
        action: "launch-contentType",
        url: this.click ? this.subLink : this.result.url,
        title: this.result.title,
        id: this.result.id,
        page: this.$route.fullPath,
        type: this.result.type,
        relevance: this.result.relevance,
        sortOrder: this.index,
        accessObject: {} as any,
        assignedContent: undefined as any,
      };

      if (this.showPremium) {
        this.setPremiumAccessInfo(telemetryObject.accessObject);
      }

      if (this.result.assigned_content) {
        telemetryObject.assignedContent =
          this.result.assigned_content?.isAssigned;
        telemetryObject.sortOrder = this.result.assigned_content?.index;
      }

      return telemetryObject;
    },

    setPremiumAccessInfo(accessObject: any) {
      if (
        this.status?.bundles?.entitlements?.some(
          (entitlement: any) => entitlement.content_bundle_name === ESSENTIALS
        )
      ) {
        accessObject.contentAccessLevel = ESSENTIALS;
      } else {
        accessObject.contentAccessLevel = FREE;
      }

      accessObject.type = "contentEntitlementAccess";
      accessObject.status = "locked";
    },

    handleTelemetryResponse() {
      if (this.showPremium && this.hasParent) {
        this.handlePremiumAccess();
      } else if (!this.hasParent) {
        this.$router.push({
          name: "Course",
          params: {
            courseId: this.result.friendly_url
              ? this.result.friendly_url
              : this.result.id,
          },
        });
      } else if (this.result?.url && isXylemeSyndicateCourse(this.result)) {
        window.open(this.resourceUrl(), "_blank", "noopener");
      } else if (this.result.publisher_name === "Cisco") {
        this.handleSpecialPublisher();
      } else {
        this.handleDefaultAction();
      }
    },

    handlePremiumAccess() {
      this.$router.push({ name: "subscription" });
    },

    handleSpecialPublisher() {
      window.location.href = this.resourceUrl();
    },

    handleDefaultAction() {
      window.open(this.resourceUrl(), "_blank", "noopener");
    },
    returnUrl(url: string) {
      if (!isString(url)) return "";
      if (window.location.href.includes("for-you")) {
        return linkBackFormat(url, "for you page");
      }
      if (window.location.href.includes("explore")) {
        return linkBackFormat(url, "explore page");
      }
      return linkBackFormat(url, "search results");
    },

    async sendRmTelemetry(content: any) {
      const contentId = content.content_id || content.id || "";
      return await telemetry.remove_from_myLearning(
        window.location.origin + this.$route.fullPath,
        `/${urlContentTypes(content.type)}/${contentId}`,
        content.title || content.name || "",
        content.type,
        contentId,
        this.isUnauth()
      );
    },

    async removeFromMyProgressList(content: any) {
      removeProgress(content.guid, content.type)
        .then(() => {
          this.sendRmTelemetry(content);
          this.removeFromMyProgress(content);
          this.fetchProgress();
        })
        .catch(() => {
          // do nothing
        });
    },
  },
  mounted() {
    EventBus.on("modalOpen", () => {
      this.openModal = true;
    });
  },
});
</script>

<template>
  <div
    data-cy="course-card"
    :class="[
      'relative z-10',
      {
        'w-[19rem]': cardView == 'Grid' && !topicView,
        'w-full': cardView != 'Grid',
        'w-[22rem]': topicView,
      },
    ]"
    v-show="result"
  >
    <the-card
      :is-unauth="isUnauth()"
      :card-view="cardView"
      :result="result"
      :status="status"
      :link="resourceUrl()"
      :cy="'course-card-link'"
      @navigate="whereTo"
      @bookmark="debouncedBookmarkMe(result)"
      @remove-progress="removeFromMyProgressList(result)"
      :is-removable="isRemovable"
      :is-admin-content="isAdminContent"
    />
    <div
      :class="[
        'mt-0',
        {
          hidden: !showPremium || !hasParent || !click,
          'mask_it absolute left-0 top-0 mt-0 h-full w-full rounded-[0.625rem] bg-[#000000]/80':
            (showPremium && click) || (hasParent && click),
        },
      ]"
    >
      <cu-reicon-lock
        type="filled-keyhole"
        dark="true"
        size="xl"
        :class="[
          'flex justify-center',
          {
            'mt-12': cardView == 'Grid',
            'mt-2': cardView != 'Grid',
          },
        ]"
      />
      <div
        class="mx-2 text-center text-13 text-white-lightest"
        v-click-outside="{
          handler: hideClicked,
          events: ['dblclick', 'click'],
          capture: true,
        }"
      >
        <p v-if="isUnauth() && !freeContent">
          This content is included in Cisco U. subscription plans.<br /><router-link
            class="underline"
            :to="{ name: 'subscription' }"
            >View your options to subscribe.</router-link
          >
        </p>
        <div v-else-if="isUnauth() && freeContent">
          <a href="/login" class="text-white-lightest underline">Sign up</a>
          to explore this free {{ result.type }}.
        </div>
        <div v-else-if="hasParent">
          <p>Courses are not sold individually. To access this course,</p>
          <a
            :href="subLink"
            class="underline"
            @click.prevent="openCartModal()"
            @keyup.prevent="openCartModal()"
            >choose a Learning Path.</a
          >
        </div>
      </div>
    </div>
  </div>
</template>
